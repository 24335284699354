<template>
  <article id="catalog-page">
    <div class="d-flex align-center justify-space-between mb-4">
      <redirect title="Catalogs" route="website.catalog" />
      <v-btn
        :loading="isImporting"
        color="primary"
        class="font-weight-medium px-3 fz-14"
        depressed
        height="32px"
        @click="onImportCatalog"
        ><v-icon>mdi-import mr-1</v-icon> Import to store</v-btn
      >
    </div>
    <h1 class="fz-24 text-capitalize mb-4 text-center">{{ staticCatalogName }}</h1>
    <p class="text-center"><v-icon>mdi-calendar-check fz-18</v-icon> Update at: {{ updatedDate }}</p>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-4 mb-6">
          <h3 class="primary--text mb-2">Images</h3>
          <CatalogImages :images="catalog.images" />
        </v-card>
        <v-card class="pa-4 mb-6">
          <h3 class="primary--text mb-2">Product Information</h3>
          <div class="d-flex align-center justify-space-between mb-1">
            <label style="opacity: 0.8">Product type:</label>
            <p class="mb-0">{{ catalog.productType || 'n/a' }}</p>
          </div>
          <div class="d-flex align-center justify-space-between mb-1">
            <label style="opacity: 0.8">Vendor:</label>
            <p class="mb-0">{{ catalog.vendor || 'n/a' }}</p>
          </div>
          <div v-if="catalog.options.length" class="mb-1">
            <label class="d-block mb-1" style="opacity: 0.8">Options: </label>
            <div class="d-flex flex-wrap" style="gap: 8px">
              <v-chip color="primary" label small v-for="(option, key) in catalog.options" :key="key"
                >{{ option.name }}: {{ option.values.join(', ') }}</v-chip
              >
            </div>
          </div>
          <div v-if="catalog.tags.length" class="mb-4">
            <label class="d-block mb-1" style="opacity: 0.8">Tags: </label>
            <div class="d-flex flex-wrap" style="gap: 8px">
              <v-chip color="primary" label small v-for="(tag, key) in catalog.tags" :key="key">{{ tag }}</v-chip>
            </div>
          </div>
          <v-expansion-panels class="catalog-description">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-0 primary--text font-weight-bold">
                Description
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="catalog.bodyHtml"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <CatalogAds :catalog="catalog" />
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-4 mb-6">
          <h3 class="primary--text mb-2">Prices</h3>
          <div class="d-flex align-center justify-space-between mb-1">
            <label style="opacity: 0.8">Price range:</label>
            <p class="mb-0">{{ parseCost(catalog.minPrice, catalog.maxPrice) }}</p>
          </div>
          <div class="d-flex align-center justify-space-between mb-1">
            <label style="opacity: 0.8">Base cost:</label>
            <p class="mb-0">{{ formatPrice(catalog.baseCost) }}</p>
          </div>
          <div class="d-flex align-center justify-space-between">
            <label style="opacity: 0.8">Profit margin:</label>
            <p class="mb-0">{{ formatPrice(catalog.profitMargin) }}</p>
          </div>
        </v-card>
        <v-card class="pa-4">
          <h3 class="primary--text mb-4">Ads Tested</h3>
          <div v-html="catalog.adsCampResult" class="ads-tested"></div>
        </v-card>
      </v-col>
    </v-row>
  </article>
</template>

<script>
import CatalogImages from './components/catalog-image';
import CatalogAds from './components/ads';
import { catalogService } from '@/apis/catalog';
import moment from 'moment';
export default {
  components: { CatalogImages, CatalogAds },
  data() {
    return {
      staticCatalogName: 'Default Title',
      catalog: {
        videoUrls: [],
        adsImages: [],
        tags: [],
        images: [],
        title: null,
        bodyHtml: null,
        vendor: null,
        productType: null,
        variants: [],
        minPrice: null,
        maxPrice: null,
        profitMargin: null,
        adsUrl: null,
        adsContent: null,
        baseCost: 0,
        options: [],
        handle: null,
        updatedDate: null,
      },
      isLoading: false,
      isImporting: false,
    };
  },
  computed: {
    updatedDate() {
      return moment(this.catalog.updatedDate).format('MMMM D, YYYY');
    },
  },
  methods: {
    async getCatalog() {
      try {
        this.isLoading = true;
        const { id } = this.$route.params;
        const { data } = await catalogService.getCatalog(id);
        if (data) {
          this.catalog = { ...data };
          this.staticCatalogName = data.title;
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:15 ~ getCatalog ~ error:', error);
      } finally {
        this.isLoading = false;
      }
    },
    formatPrice(price) {
      if (!price) return '$0';
      return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
    },
    parseCost(minPrice, maxPrice) {
      const from = this.formatPrice(minPrice);
      const to = this.formatPrice(maxPrice);
      return `${from} ~ ${to}`;
    },
    async onImportCatalog() {
      try {
        this.isImporting = true;
        const currentStore = localStorage.getItem('store-id');
        const dataPost = {
          products: [{ ...this.catalog, stores: [currentStore], catalog: this.catalog._id }],
        };
        await catalogService.importProductToStore(dataPost);
      } catch (error) {
        console.log('🚀 ~ file: index.vue:186 ~ onImportProduct ~ error:', error);
      } finally {
        this.isImporting = false;
      }
    },
  },
  created() {
    this.getCatalog();
  },
};
</script>
<style lang="scss" scoped>
.catalog-description .v-expansion-panel {
  background-color: transparent !important;
  &::before {
    box-shadow: none !important;
  }
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset !important;
}
</style>

import storeRequest from './request/storeRequest';

export const catalogService = {
  getCatalogs(params) {
    return storeRequest.get('/catalogs', {
      params,
    });
  },
  countCatalogs() {
    return storeRequest.get('/catalogs/count');
  },
  getCatalog(id) {
    return storeRequest.get('/catalogs/' + id);
  },
  importProductToStore(data) {
    return storeRequest.post('/catalogs/import/products', data);
  },
  getCatalogTags() {
    return storeRequest.get('/catalogs/admin/tags');
  },
};
